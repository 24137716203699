import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { BrochviewComponent } from './brochview/brochview/brochview.component';
import { HomeComponent } from './Home/home/home.component';
import { DownloadComponent } from './Home/download/download.component';
import { LayoutComponent } from './layout/layout/layout.component';
import { LoginComponent } from './login/login/login.component';
import { PdfviewComponent } from './pdfview/pdfview/pdfview.component';



const routes: Routes = [

  {path: '', component: LoginComponent},
  {path: 'home', component: HomeComponent},
  {path: 'download/:brochureId', component: DownloadComponent},
  {path: 'pdf', component: PdfviewComponent},
  {path: 'broch', component: BrochviewComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
