import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { AppMain } from 'src/app/app.main';

@Component({
  selector: 'app-pdfview',
  templateUrl: './pdfview.component.html',
  styleUrls: ['./pdfview.component.css']
})
export class PdfviewComponent implements OnInit {

  filePath: any;
  docBase64:any;
  selecteQRPath: string = '';
  title: string = '';
  description: string = '';
  isLoading: boolean = false;
  constructor(
    private sanitizer: DomSanitizer,
    public appMain: AppMain,
    private dialogRef: MatDialogRef<PdfviewComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: any
  ) {
    //console.log(this.dialogData);
    var r = this.sanitizer.bypassSecurityTrustResourceUrl(this.dialogData.docPath);
    this.filePath = this.dialogData.docPath ;
    // console.log(this.filePath);
    this.selecteQRPath = this.dialogData.qrPath;
    this.title = this.dialogData.title;
    this.description = this.dialogData.description;
    //this.docBase64 = this.sanitizer.bypassSecurityTrustResourceUrl(this.dialogData.docBase64);
    //console.log('pdf view:', this.docBase64);
  }

  ngOnInit() {

  }

  back() {
    this.isLoading = false;
    this.dialogRef.close();
  }
 
}
