import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { AppMain } from 'src/app/app.main';
import { PdfviewComponent } from 'src/app/pdfview/pdfview/pdfview.component';
import { ServiceService } from 'src/app/service.service';
import { SvcAku } from 'src/app/_svcs/aku.svc';

@Component({
  selector: 'app-brochview',
  templateUrl: './brochview.component.html',
  styleUrls: ['./brochview.component.css']
})
export class BrochviewComponent implements OnInit {

  filePath: any;
  selecteQRPath: string = '';
  title: string = '';
  description: string = '';
  Thumbnail: string = '';
  docBase64: string = '';
  docUrl: string = '';
  imageURL: string = "";
  isLoading: boolean = false;
  selectedAgenciesId: number = 0;
  brochureId:number = 0;
  brochureAppId = '';

  qrUrl: string = '';

  constructor(
    private sanitizer: DomSanitizer,
    public appMain: AppMain,
    private dialog: MatDialog,
    private service: ServiceService, private svcAku: SvcAku,
    private dialogRef: MatDialogRef<BrochviewComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: any
  ) {
    console.log(this.dialogData);
    this.filePath = this.dialogData.docPath;
    this.selecteQRPath = this.dialogData.qrPath;
    this.title = this.dialogData.title;
    this.description = this.dialogData.description;
    this.Thumbnail = this.dialogData.Thumbnail;
    this.docBase64 = this.dialogData.docBase64;
    this.brochureId = this.dialogData.brochureId;
    this.brochureAppId = this.dialogData.brochureAppId;
    this.qrUrl =  this.dialogData.docUrl;//location.origin + '/download/' + this.brochureId;
    //console.log('qr', 'https://drive.google.com/viewerng/viewer?embedded=true&url='+this.qrUrl);
    //console.log(encodeURI(this.qrUrl));
    // console.log(encodeURI(this.qrUrl).replace(/\=/g,"%3D"));
    // console.log(encodeURI(this.qrUrl).replace(/\&/g,"%26"));
    var str = encodeURI(this.qrUrl).replace(/\=/g,"%3D");
    str = str.replace(/\&/g,"%26");
    // console.log(str);
    // console.log(this.filePath);
    //str = 'https://firebasestorage.googleapis.com/v0/b/wst-ebrouchure-demo-7efe4.appspot.com/o/brochures%252FB-202203211701257ZIMDS%252Fmaintenance-merged.pdf%23toolbar%3D0?alt%3Dmedia%26token%3D8441e420-433c-45a7-92e4-ba2cab03632c'
    this.qrUrl = 'https://drive.google.com/viewerng/viewer?embedded=true&url='+str;
    // console.log('https://drive.google.com/viewerng/viewer?embedded=true&url='+str);
  }

  ngOnInit() {
    this.imageURL = this.appMain.imageURL;
  }
  back() {
    this.isLoading = false;
    this.dialogRef.close();
  }
  showPDF() {
      this.dialogRef.close();
    let btn = document.getElementById("showPDF");
  
    //btn.click();
//     var r = this.sanitizer.bypassSecurityTrustResourceUrl(this.filePath);
    this.selecteQRPath = this.selecteQRPath;// this.appMain.imageURL+e;
    //this.viewpPdf(this.filePath,this.selecteQRPath);
    const timeout = 180000;
    this.svcAku.log('VIEW-PDF', this.brochureAppId);
    const dialogRef = this.dialog.open(PdfviewComponent, {
      width: "95%",
      disableClose: false,
      //data: { docPath: this.filePath, qrPath: this.selecteQRPath, title: this.title, description: this.description, docBase64:this.docBase64 }
      data:{
        docPath: this.sanitizer.bypassSecurityTrustResourceUrl(this.qrUrl),
        qrPath: this.qrUrl, title: this.title, description: this.description
      }
    })
    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
         dialogRef.close();
      }, timeout)
    })
  }

}

// showPDF(e: string, id: number,title:string, description:string  ) {
//   let btn = document.getElementById("showPDF");
//   //btn.click();
//   this.filePath = this.sanitizer.bypassSecurityTrustResourceUrl(this.appMain.imageURL + "Images/Brouchure/" + e+"?"+ new Date());
//   this.selecteQRPath = this.appMain.apiURL + "BrouchureQR/" + id + "/";// this.appMain.imageURL+e;
//   //this.viewpPdf(this.filePath,this.selecteQRPath);
//   this.dialog.open(PdfviewComponent, {
//     width: "90%",
//     disableClose: false,
//     data: { docPath: this.filePath, qrPath: this.selecteQRPath, title: title, description:description}
//   })
// }

export interface brouchure {
  brouchureId: number;
  brouchureTitle: string;
  brouchureThumbnail: string;
  brouchureDocument: string;
  languageId: number;
  agenciesId: number;
  thumbBase64: string;
  documentBase64: string;
  brouchureDescription: string;

  brouchureStore: BrouchureStore[];
}
export interface BrouchureStore {
  brouchureId: number;
  storeId: number;
}