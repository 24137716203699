import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { from } from 'rxjs';
import { AppMain } from 'src/app/app.main';
import { ServiceService } from 'src/app/service.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AngularFireDatabase } from '@angular/fire/database';
import { SyncAsync } from '@angular/compiler/src/util';
import { MatDialog } from '@angular/material';
import { PdfviewComponent } from 'src/app/pdfview/pdfview/pdfview.component';
import { SvcAku } from 'src/app/_svcs/aku.svc';
import { BrochviewComponent } from 'src/app/brochview/brochview/brochview.component';
import { SvcAuth } from 'src/app/_svcs/auth.svc';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.css']
})
export class DownloadComponent implements OnInit {

  public CurrentDate: Date = new Date();

  formData: login;
  isLogin: boolean = false;
  returnUrl: string = "";
  isLoading: boolean = false;
  isloggedIn: boolean = false;
  imageURL: string = "";
  language: language;
  langList:any = []; agencyList:any = [];
  brochure_All = [];
  brochure_English = [];
  brochure_Tamil = [];
  brochure_Melayu = [];
  brochure_Chinnese = [];
  video: video;
  videoURL: any = {};
  agencies:Agencies;

  selectedAgenciesId:number=0;

  selectedPdfPath: string = "http://localhost:50637/Images/Brouchure/chas.pdf?" + this.CurrentDate;
  selecteQRPath: string = '';
  pdfUrl = 'assets/pdf/hdb.pdf';
  filePath: any;
  _selectedAgencies:string = "All Agency";
  _selectedLangauge:string = "";
  pagecontent: any;
  savepassword:boolean = false;

  youtubeVideoUrl:any;

  topright = false;
  showLanguageOptions = false; backgroundColor = ''; brochureId = 0;

  @Input("disableNavigate") disableNavigate: boolean = false;
  @Output("loginStatusEnent") loginStatusEnent = new EventEmitter();
  @ViewChild('pdf', { static: true }) pdf: ElementRef;
  //@ViewChild('pdf', { static: true }) pdf: ElementRef;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public db: AngularFireDatabase,
    private service: ServiceService,
    public appMain: AppMain,
    private renderer: Renderer2,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog, public svcAku: SvcAku
    //public svcAuth: SvcAuth
  ) {
    //this.filePath = this.sanitizer.bypassSecurityTrustResourceUrl("http://localhost:50637/Images/Brouchure/chas.pdf");
  }

  setpdf1() {
    this.renderer.removeAttribute(this.pdf.nativeElement, "src");
    setTimeout(() => {
      this.renderer.setAttribute(this.pdf.nativeElement, "src",
        "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf")
    })
  }
  afterViewInit() {
    this.setpdf1();
  }

  ngOnInit() {
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '';
    // check the login status
    if (localStorage.getItem("IsLoggedIn") != undefined) {
      if (localStorage.getItem("IsLoggedIn") == "1") 
        this.isLogin = true;
      else this.isLogin = false;
    }
    else {
      this.isLogin = false;
    }
    this.imageURL = this.appMain.imageURL;
    this.videoURL = this.appMain.imageURL + "Images/Video/";
    //this.formData = { password: '', userName: '' };
    window.scrollTo(0, 0);
    this.dataLoad();
    //this.dataLoadV01();
    this.configsLoad();
  }
  configsLoad()
  {
    this.db.list('configs').snapshotChanges().subscribe(res=>{
      this.isLoading = false;
      var _recList = this.svcAku.fireRecords(res);
      
      _recList.forEach(r=>{
        if(r.appId == 'bg') this.backgroundColor = r.color;
        if(r.appId == 'lang') this.showLanguageOptions = r.show == 1;
      });
    });
  }
  dataLoad(){
    this.brochureId = Number(this.route.snapshot.paramMap.get('brochureId'));
    if(this.brochureId<1){
      alert('Content not found!');
      return;
    }
    this.db.list('lang').snapshotChanges().subscribe(resLang=>{
      this.langList = this.svcAku.fireRecords(resLang).filter(r=>r.s==1);
      if(this.langList.length<1){
        alert('No Language found!');
        return;
      }
      this._selectedLangauge = this.langList[0].n;

      this.db.list('agency').snapshotChanges().subscribe(res=>{
        this.agencyList = this.fireRecords(res).filter(r=>r.s==1);
        if(this.agencyList.length<1){
          alert('No Agency found!');
          return;
        }
      });
      this.dataLoadBrochures(0);
    });
  }
  dataLoadBrochures(agencyId){
    var _langId = 0;
    this.langList.forEach(l => {
      if(l.n == this._selectedLangauge) _langId = l.id;
    });
    if(_langId==0){
      alert('Language not found!');
      return;
    }
    this.db.list('brochure').snapshotChanges().subscribe(res=>{
      var _records = this.svcAku.fireRecords(res);
      //console.log('records:', _records);
      this.brochure_All = [];
      this.brochure_Chinnese = [];
      this.brochure_English = [];

      _records.forEach(r=>{
        //if(!this.filterAgency(r, agencyId)) return;
        //if(r.langId != _langId) return;
        this.brochure_All.push(r);
        // if(r.langId == 1) this.brochure_English.push(r);
        // if(r.langId == 2) this.brochure_Chinnese.push(r);
      });
      console.log('records:', this.brochure_All);
      this.dataLoadImgDoc();
    });
  }
  dataLoadImgDoc(){
    this.brochure_All.forEach(r=>{
      //console.log(r.id, this.brochureId);
      if(r.id != this.brochureId) return;
      //console.log(r);
      // this.db.list(`brochure-img/${r.appId}`).snapshotChanges().subscribe(resImg=>{
      //   var _imgList = this.svcAku.fireRecords(resImg);
      //   if(_imgList.length>0){
      //     r.imgB64 = _imgList[0].b64;
      //   }
      // });
      this.db.list(`brochure-doc/${r.appId}`).snapshotChanges().subscribe(resImg=>{
        var _docList = this.svcAku.fireRecords(resImg);
        if(_docList.length>0){
          r.docB64 = _docList[0].b64;
        }
        // console.log('downloading ---->', r.id);
        // console.log(r);
        const link = document.createElement("a");
        link.href = r.docB64;
        var _fileName = r.doc ? r.doc : 'download.pdf';
        link.download = `${_fileName}`
        link.click();
      });
    });
  }
  filterAgency(argRecord, agencyId){
    if(agencyId==0) return true;
    if(!argRecord.agencies || argRecord.agencies.length<1) return false;
    return argRecord.agencies.filter(a=>a.id == agencyId).length>0;
  }
  dataLoadV01(){
    this.getLanguage();
    this.getBrouchure();
    // this.getVideo();
    // this.getBrouchureQR();
    this.getAgencies();
  }

  Logout() {
    localStorage.removeItem("userToken");
    localStorage.setItem("IsLoggedIn", "0");
    this.ngOnInit();
    this.isloggedIn = false;
  }
  getAgencies() {
    this.agencyList = [];
    this.db.list('agency').snapshotChanges().subscribe(res=>{
      var _recList = this.fireRecords(res);
      _recList.forEach(r=>{
        if(r.s != 1) return;
        this.agencyList.push(r);
      });
      if(this.agencyList.length<1){
        alert('No Agency found!');
        return;
      }
    });
    // this.service.getAgencies().then((res: any) => {
    //   this.agencies = res.item;
    //   console.log(this.agencies);
    //   this.isLoading = false;
    // })
  }
  getLanguage() {
    this.langList = [];
    this._selectedLangauge = '';
    this.db.list('lang').snapshotChanges().subscribe(res=>{
      this.isLoading = false;
      var _recList = this.fireRecords(res);
      _recList.forEach(r=>{
        if(r.s != 1) return;
        this.langList.push(r);
      });
      if(this.langList.length<1){
        alert('No Language found!');
        return;
      }
      this._selectedLangauge = this.langList[0].n;
    });
    // this.service.getLanguage().then((res: any) => {
    //   this.language = res.item;
    //   console.log('lang: ', res);
    //   this.isLoading = false;
    // })
  }
  langSelect(arg){
    this._selectedLangauge = arg.n;
    this.dataLoadBrochures(this.selectedAgenciesId);
  }
  selectedAgencies(e:number,name:string){
    this.selectedAgenciesId=e;
    this._selectedAgencies=name;
    //this.getBrouchure();
    this.dataLoadBrochures(this.selectedAgenciesId);
  }
  getBrouchure() {
    var storeId = localStorage.getItem("storeId");
    storeId = '2';
    this.service.getBrouchure(Number(storeId), 0,this.selectedAgenciesId).then((res: any) => {
      this.brochure_All = res.brouchureList;
      this.isLoading = false;
      console.log(this.brochure_All);
    })
    this.service.getBrouchure(Number(storeId), 1,this.selectedAgenciesId).then((res: any) => {
      this.brochure_English = res.brouchureList;
      this.isLoading = false;
    })
    // this.service.getBrouchure(Number(storeId), 2,this.selectedAgenciesId).then((res: any) => {
    //   this.brochure_Chinnese = res.brouchureList;
    //   this.isLoading = false;
    // })
    // this.service.getBrouchure(Number(storeId), 3,this.selectedAgenciesId).then((res: any) => {
    //   this.brochure_Melayu = res.brouchureList;
    //   this.isLoading = false;
    // })
    // this.service.getBrouchure(Number(storeId), 4,this.selectedAgenciesId).then((res: any) => {
    //   this.brochure_Tamil = res.brouchureList;
    //   this.isLoading = false;
    // })
  }
  getBrouchureQR() {
    this.service.getBrouchureQR(Number(1)).then((res: any) => {
      res.res
      this.isLoading = false;
    })
  }
  getVideo() {
    var storeId = localStorage.getItem("storeId");
    this.service.getVideo(Number(storeId)).then((res: any) => {
      this.video = res.videoList;
      this.isLoading = false;
    })
  }
  // getVideo() {
  //   var storeId = localStorage.getItem("storeId");
  //   this.service.getVideo(Number(storeId)).then((res: any) => {
  //     this.video = res.videoList;
  //     this.youtubeVideoUrl =  this.sanitizer.bypassSecurityTrustResourceUrl(this.video[0].youtubeVideoLink);
  //     this.isLoading = false;
  //   })
  // }

  // PDF & QR VIEW 
  // viewpPdf(docPath: string, QrPath: string) {
  //   this.dialog.open(PdfviewComponent, {
  //     width: "700px",
  //     disableClose: true,
  //     data: { docPath: docPath, qrPath: QrPath }
  //   })
  // }

  // showPDF(e: string, id: number,title:string, description:string) {
  //   let btn = document.getElementById("showPDF");
  //   //btn.click();
  //   this.filePath = this.sanitizer.bypassSecurityTrustResourceUrl(this.appMain.imageURL + "Images/Brouchure/" + e+"?"+ new Date());
  //   this.selecteQRPath = this.appMain.apiURL + "BrouchureQR/" + id + "/";// this.appMain.imageURL+e;
  //   //this.viewpPdf(this.filePath,this.selecteQRPath);
  //   this.dialog.open(PdfviewComponent, {
  //     width: "90%",
  //     disableClose: false,
  //     data: { docPath: this.filePath, qrPath: this.selecteQRPath, title: title, description:description }
  //   })
  // }
  showPDFByItem(arg){
    const timeout = 180000;
    const dialogRef = this.dialog.open(BrochviewComponent, {
      width: "95%",
      disableClose: false,
      data: { docPath: this.filePath, qrPath: this.selecteQRPath, title: arg.n, description:arg.d, Thumbnail:arg.imgB64, docBase64:arg.docB64 }
    })
    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
         dialogRef.close();
      }, timeout)
    })
  }
  showPDF(e: string, id: number,title:string, description:string, Thumbnail:string  ) {
    let btn = document.getElementById("showPDF");
    //btn.click();
    this.filePath = this.sanitizer.bypassSecurityTrustResourceUrl(this.appMain.imageURL + "Images/Brouchure/" + e+"?"+ new Date());
    this.selecteQRPath = this.appMain.apiURL + "BrouchureQR/" + id + "/";// this.appMain.imageURL+e;
    //this.viewpPdf(this.filePath,this.selecteQRPath);
    const timeout = 180000;
    const dialogRef = this.dialog.open(BrochviewComponent, {
      width: "95%",
      disableClose: false,
      data: { docPath: this.filePath, qrPath: this.selecteQRPath, title: title, description:description, Thumbnail:Thumbnail}
    })
    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
         dialogRef.close();
      }, timeout)
    })
  }
  // showQR(e: string){
  //   this.service.getBrouchureQR(Number()).then((res: any) => {
  //     this.brochure_English = res.brouchureList;      
  //     this.isLoading = false;
  //   })
  // }
  videotab(){
    let btn = document.getElementById("video-tab");
    btn.click();
    this.topright = true;// !this.topright;
  }
  english(){
    let btn = document.getElementById("en-tab");
    btn.click();
    this.topright = false;//!this.topright;
  }

  fireRecords(res){
    var rtn = [];
    res.forEach(r => {
      var _rec:any = r.payload.val();
      _rec.appId = r.key;
      rtn.push(_rec);
    });
    return rtn;
  }
}

export interface login {
  userName: string;
  password: string;
}

export class language {
  languageId: number;
  languageName: string;
}

export interface brouchure {
  brouchureId: number;
  brouchureTitle: string;
  brouchureThumbnail: string;
  brouchureDocument: string;
  languageId: number;
  agenciesId: number;
  thumbBase64: string;
  documentBase64: string;
  brouchureDescription:string;

  brouchureStore: BrouchureStore[];
}
export interface BrouchureStore {
  brouchureId: number;
  storeId: number;
}
export class video {
  videoId: number;
  videoTitle: string;
  languageId: number;
  videoThumbnail: string;
  thumbBase64: string;
  videoDocument: string;
  documentBase64: string;
  videoStore: VideoStore[];
}
export interface VideoStore {
  videoId: number;
  storeId: number;
}

export class Agencies{
  agenciesId:number;
  agenciesName:string;
  agenciesDescription:string;
}
