import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppMain } from './app.main';


@Injectable({
  providedIn: 'root'
})
export class ServiceService {

  constructor(
    public appMain: AppMain,
    public http: HttpClient
  ) { }



  public saveCustomer(formData: any) {    const httpOptions = new HttpHeaders({
    'No-Auth': 'True'
  });
    
    return this.http.post(this.appMain.apiURL + "Customer", formData, { headers: httpOptions }).toPromise();
  }

  public Login(username: string, password: string) {
    const httpOptions =  new HttpHeaders({
         'No-Auth': 'True',
        'Content-Type':  'application/json',
        'Authorization': 'Basic ' + btoa(username + ":" + password )
      })
    ;

    return this.http.post(this.appMain.apiURL + "token/1", "", { headers: httpOptions }).toPromise();
  } 

  public getCurrentUserDetail(username:string){    
    return this.http.get(this.appMain.apiURL+"User/GetCurrentUserDetails/"+username ).toPromise();
  }
 
  public getLanguage() {
    const httpOptions = new HttpHeaders({
      'No-Auth': 'True'
    });
    return this.http.get(this.appMain.apiURL + "Language", { headers: httpOptions }).toPromise();
  }

  public getAgencies() {
    const httpOptions = new HttpHeaders({
      'No-Auth': 'True'
    });
    return this.http.get(this.appMain.apiURL + "Agencies", { headers: httpOptions }).toPromise();
  }
   
  public getBrouchure(StoreId:number, LanguageId:number,agenciesId:number) {
    const httpOptions = new HttpHeaders({
      'No-Auth': 'True'
    });
    return this.http.get(this.appMain.apiURL + "BrouchureByStore/"+StoreId+"/"+LanguageId+"/"+agenciesId, { headers: httpOptions }).toPromise();
  }

  public getVideo(StoreId:number) {
    const httpOptions = new HttpHeaders({
      'No-Auth': 'True'
    });
    return this.http.get(this.appMain.apiURL + "GetVideoByStore/"+StoreId, { headers: httpOptions }).toPromise();
  }

  public getBrouchureQR(brouchureId:number) {
    const httpOptions = new HttpHeaders({
      'No-Auth': 'True'
    });
    return this.http.get(this.appMain.apiURL + "BrouchureQR/"+brouchureId+"/", { headers: httpOptions }).toPromise();
  }

}
