import { Injectable } from "@angular/core";
import * as _moment from 'moment';
import { AngularFireDatabase } from '@angular/fire/database';

@Injectable({ providedIn: 'root' })
export class SvcAku{
	constructor(private db: AngularFireDatabase){}

	fireRecords(res){
    var rtn = [];
    res.forEach(r => {
      var _rec:any = r.payload.val();
      _rec.appId = r.key;
      rtn.push(_rec);
    });
    return rtn;
  }
  sortListBy(field:string, sortDirection:number, source:any, childField:string=null, childIndex:number=null){
    source.sort((a: any, b: any) => {
        // console.log(a);
        // console.log(`${a} vs ${b} @ ${childIndex}`);
        var _a = a[field];
        var _b = b[field];
        //console.log(`${_a} vs ${_b} @ ${childIndex}`);
        if(childField!=null){
            _a = _a[childField];
            _b = _b[childField];
        }
        if(childIndex!=null){
            //console.log(`${_a} vs ${_b} @ ${childIndex} <<`);
            _a = _a[childIndex];
            _b = _b[childIndex];
        }
        //console.log(`${_a} vs ${_b} @ ${childIndex}`);
        if(isNaN(_a) || isNaN(_b)){
          _a = _a.toLowerCase();
          _b = _b.toLowerCase();
        }
        if (_a < _b) {
            return sortDirection==1 ? -1 : 1;
        } else if (_a > _b) {
            return sortDirection==1 ? 1 : -1;
        } else {
            return 0;
        }
    });
      //this.recordsFiltered = this.recordsFiltered;
  }
  stringAppId(code):string{
    var _prefix = this.stringRandom(3).toUpperCase();
    var _suffix = this.stringRandom(3).toUpperCase();
    return `${code}-${_moment.utc().format("YYYYMMDDHHmmss")}${_prefix}${_suffix}`;
  }
  stringRandom(length) {
      var result = '';
      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
  }
  log(_code, _desc=''){
    var _momentNowUtc = _moment().utc();
    var _dayPath = `${_momentNowUtc.year()}/${_momentNowUtc.month()+1}/${_momentNowUtc.date()}/u`;
    var _timeCode = _momentNowUtc.format('HHmmssSSS');//this.stringAppId('L');
    var _userId = localStorage.getItem("uiUserId");
    if(!_userId) return;
    var _log:any = {
      //t: _momentNowUtc.format('YYYYMMDDHHmmss'),
      c: _code
    };
    if(_desc != '') _log.d = _desc;
    this.db.object(`logs/${_dayPath}/${_userId}/${_timeCode}`).set(_log);
  }
}
