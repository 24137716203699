import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DownloadComponent } from './Home/download/download.component';
import { HomeComponent } from './Home/home/home.component';
import { LayoutComponent } from './layout/layout/layout.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule, MatProgressBarModule, MatProgressSpinnerModule } from '@angular/material';
import { AppMain } from './app.main';
import { AuthGuard } from './auth/auth.guard';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';

import { AuthInterceptor } from './auth/auth.interceptor';
import { FormsModule } from '@angular/forms';
// import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MatVideoModule } from 'mat-video';
import { PdfviewComponent } from './pdfview/pdfview/pdfview.component';
import { BrochviewComponent } from './brochview/brochview/brochview.component';
import { LoginComponent } from './login/login/login.component';
// import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { environment } from '../environments/environment';
import { QRCodeModule } from 'angularx-qrcode';

export function init_app(app: AppMain) {
  return () => app.initializeApp();
}

@NgModule({
  declarations: [
    AppComponent,
    DownloadComponent,
    HomeComponent,
    LayoutComponent,
    PdfviewComponent,
    BrochviewComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatDialogModule,
    FormsModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    // PdfViewerModule,
    MatVideoModule,
    // NgxExtendedPdfViewerModule
    AngularFireModule.initializeApp(environment.firebase), AngularFireDatabaseModule, AngularFirestoreModule, AngularFireAuthModule,
    QRCodeModule
  ],
  providers: [AppMain,
    { provide: APP_INITIALIZER, useFactory: init_app, deps: [AppMain], multi: true },
    AuthGuard, {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
