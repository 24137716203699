import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class AppMain {

  public imageURL: string;
  public apiURL: string;
  public serverip: string;
  public version: string; 

  constructor(
    private http: HttpClient
  ) { }

  initializeApp(): Promise<any> {
    const httpOptions = new HttpHeaders({
      'No-Auth': 'True'
    });
    const promise = this.http.get('assets/Config.json', { headers: httpOptions })
      .toPromise()
      .then((res: any) => {
        console.log(res);
        this.imageURL = res.imageURL;
        this.apiURL = res.apiURL;
        this.serverip = res.serveripaddress;
        this.version = res.version; 
        return res;
      });
    return promise;
  }

  ngOnInit(): void {
  }

  public fillConfiguration() {

  }
}
