import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppMain } from 'src/app/app.main';
import { ServiceService } from 'src/app/service.service';
import { SvcAuth } from 'src/app/_svcs/auth.svc';
import { SvcAku } from 'src/app/_svcs/aku.svc';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  formData: login;
  isLogin: boolean = false;
  savepassword:boolean = false;
  isLoading: boolean = false;
  returnUrl: string = "";
  isloggedIn: boolean = false;


  @Input("disableNavigate") disableNavigate: boolean = false;
  @Output("loginStatusEnent") loginStatusEnent = new EventEmitter();
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: ServiceService,
    private svcAuth: SvcAuth, private svcAku:SvcAku,
    public appMain: AppMain,
  ) { }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '';
    this.formData = { password: '', userName: '' };

    if(localStorage.getItem("savepassword") != undefined )
    {
      if(localStorage.getItem("savepassword")=="1")
      {
        this.savepassword = true;
        if( localStorage.getItem("userName") != undefined )
        {
          this.formData.userName = localStorage.getItem("userName");
        }
        if( localStorage.getItem("password") != undefined )
        {
          this.formData.password = localStorage.getItem("password");
        }

      }
    }
  }

  login(){
    this.isLoading = true;
    var _userName = this.formData.userName;
    if(!_userName.includes('@'))
      _userName += '@ebrochure.com';
    this.svcAuth.login(_userName, this.formData.password).then(res=>{
      this.isLoading = false;
      console.log(res);
      this.svcAuth.accInfoSet(JSON.stringify({uid: res.user.uid, email: res.user.email}));
      localStorage.setItem("uiUserId", res.user.uid);
      this.svcAku.log('LOGIN-OK');
      this.router.navigate(["/home"]);
    }).catch(err=>{
      //console.log(err);
      this.isLoading = false;
      alert('Login failed. Tray again!');
    })
  }
  loginV01() {
    this.isLoading = true;

    this.service.Login(this.formData.userName, this.formData.password).then((res: any) => {
      if (res.status) {
        localStorage.setItem('userToken', res.token);
        // afer login store the current user details in local storeage
        this.service.getCurrentUserDetail(this.formData.userName).then((res: any) => {
          if (res.status) {

            localStorage.setItem("UserName", res.user[0].userName);
            //localStorage.setItem("GroupId", res.user[0].groupId);
            //localStorage.setItem("GroupName", res.user[0].groupName);
            localStorage.setItem("UserId", res.user[0].id);
            localStorage.setItem("FirstName", res.user[0].firstName);
            localStorage.setItem("LastName", res.user[0].lastName);
            localStorage.setItem("IsLoggedIn", "1");
            localStorage.setItem("storeId", res.user[0].storeId);
            localStorage.setItem("storeName", res.user[0].storeName);
            localStorage.setItem("location", res.user[0].location);
            localStorage.setItem("contactPerson", res.user[0].contactPerson);
            localStorage.setItem("contactNumber", res.user[0].contactNumber);
            localStorage.setItem("note", res.user[0].note);
            if (this.disableNavigate == true) {
              this.loginStatusEnent.emit(1);
            } else
            this.router.navigate(["/home"]);
            this.isloggedIn = true;

            if(this.savepassword == true)
              {

                localStorage.setItem("userName", this.formData.userName);
                localStorage.setItem("password", this.formData.password);
                localStorage.setItem("savepassword", "1");

              }
              else{
                localStorage.removeItem("userName");
                localStorage.removeItem("password");
                localStorage.removeItem("savepassword");
              }
          }
          else {
            alert(res.message);
            this.isLoading = false;
          }
        })
      }
      else {
        this.isLoading = false;
        alert(res.message);
        return;
      }
    });
  }

}


export interface login {
  userName: string;
  password: string;
}
