// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyD2LR3GEDzj16MKn0Ngqv-J-mZRQyl7bKE",
    authDomain: "wst-psc.firebaseapp.com",
    projectId: "wst-psc",
    storageBucket: "wst-psc.appspot.com",
    messagingSenderId: "712551800139",
    appId: "1:712551800139:web:4d982bafbfda0f19eadcce",
    measurementId: "G-0BWHQ2VQGJ"

    // apiKey: "AIzaSyCr7Zm_tMWm43-G6jvXf76m2HV7bbUA6yU",
    // authDomain: "wst-ebrouchure-demo-7efe4.firebaseapp.com",
    // projectId: "wst-ebrouchure-demo-7efe4",
    // storageBucket: "wst-ebrouchure-demo-7efe4.appspot.com",
    // messagingSenderId: "536574146123",
    // appId: "1:536574146123:web:01a5ce630bfd3d48ea7fce",
    // measurementId: "G-0C0FLJFWFZ"
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
