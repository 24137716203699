import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {

  formData: login;
  returnUrl: string = "";

  constructor(
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '';

    this.formData = { password: '', userName: '' };
    window.scrollTo(0, 0);

  }

  

}

export interface login {
  userName: string;
  password: string;
}
