import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, from } from 'rxjs';




@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private route: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {

    // check the user is already login or not
    // if (localStorage.getItem('userToken') != null)
    //   return true;
    if(localStorage.getItem('accInfo') != null)
      return true;
    else {
      //this.route.navigateByUrl("Login");
      this.route.navigate(['/login'], { queryParams: { returnUrl: state.url } })
    }
    return false;
  }
}
